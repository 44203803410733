<template>
  <div style="width: 1300px;" class="mt-4">
    <div class="d-flex justify-space-between mb-2">
      <!-------- LEFT ITEMS ---------->
      <div class="d-flex align-center pr-5">
        <!-- Date picker -->
        <span
          class="mr-2"
          style="font-size:12px;color:#000000; margin-left:10px !important"
          >体験宿泊日</span
        >
        <tvos-date-picker v-model="filter.fromDate" />
        <div class="d-flex align-center" v-if="filterPic">
          <span class="text-12px color-title">{{
            $t('myPage.ExperienceLodging.salesRepresentative')
          }}</span>
          <pic-select v-model="filter.picId" 
            class="ml-2 mr-4 v-select-custom"/>
        </div>
        <!-- End date picker -->
        <div class="d-flex align-center canceled-reservation">
          <span class="text-12px color-title">{{
            $t('myPage.ExperienceLodging.LastActivityDate')
          }}</span>
          <v-select
            :items="canceledReservation"
            item-text="name"
            item-value="id"
            v-model="filter.isCancelled"
            dense
            outlined
            style="width: 80px"
            hide-details
            class="ml-2 mr-4 v-select-custom"
          ></v-select>
        </div>
      </div>

      <!-------- RIGHT ITEMS ---------->
      <div class="d-flex justify-end mt-2">
        <span class="text-12px color-title" style="margin-top:7px">総計</span>
        <span class="text-18px" style="color: #0b6786">{{ total }}</span>
        <span class="text-12px" style="color: #0b6786;margin-top:7px">{{
          $t('commons.item')
        }}</span>
      </div>
    </div>
    <!-- ================================================================ -->
    <v-card>
      <Table
        :attr="{
          'server-items-length': total,
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
          class: 'mt-4',
        }"
        ref="table"
        :itemsPerPage="5"
        :itemsPerPageOptions="[5, 10, 20, 50, 100, 200, 500]"
        :headers="headerPurchaseEvaluation"
        :total="total"
        :filter="filter"
        :items="searchClientListExp"
        :funReset="callAPI"
        :multi-sort="true"
        :sortField="[]"
      >
        <template v-slot:[`item.LatestAdvertisingMeasures`]="{ item }">
          <div class="text-overflow">
            <span>{{ item.LatestAdvertisingMeasures }}</span>
          </div>
        </template>
        <template v-slot:[`item.LatestActivityStatus`]="{ item }">
          <div class="text-overflow">
            <span>{{ item.LatestActivityStatus }}</span>
          </div>
        </template>
        <template v-slot:[`item.ItemsShared`]="{ item }">
          <div class="text-overflow">
            <span>{{ item.ItemsShared }}</span>
          </div>
        </template>

        <template v-slot:[`item.isCompany`]="{ item }">
          {{ item.isCompany ? '法人' : ' 個人' }}
        </template>
        <template v-slot:[`item.pic.name`]="{ item }">
          {{ item.pic ? item.pic.name : '' }}
        </template>
        <template v-slot:[`item.lastBooking.computed.status`]="{ item }">
          <span v-if="item.lastBooking">
            {{ item.lastBooking.computed ? item.lastBooking.computed.status : '' }}
          </span>
        </template>
        <template v-slot:[`item.lastBooking.room.roomType.facility.name`]="{ item }">
          <span v-if="item.lastBooking && item.lastBooking.room && item.lastBooking.room.roomType">
            {{ item.lastBooking.room.roomType.facility ? item.lastBooking.room.roomType.facility.name : '' }}
          </span>
        </template>
        <template v-slot:[`item.lastBooking.checkInDate`]="{ item }">
          {{ item.lastBooking ? item.lastBooking.checkInDate : '' }}
        </template>
        <template v-slot:[`item.numDate`]="{ item }">
          {{
            calculateDays(
              item.lastBooking.checkOutDate,
              item.lastBooking.checkInDate,
            ) + 1
          }}
        </template>
        <template
          :disabled="!checkPerUser"
          v-slot:[`item.ReservationDetails`]="{ item }"
        >
          <v-btn icon @click="redirePagePMS(item)">
            <v-icon x-large class="btn-crm-primary">mdi-pencil-circle</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.CustomerDetails`]="{ item }">
          <v-btn
            icon
            class="t-btn--prm"
            :to="{ name: 'customer-details', params: { id: item.id } }"
            target="_blank"
          >
            <v-icon> mdi-human-handsup</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-card>
  </div>
</template>

<script>
import { calculateDays } from '@/utils/dateUtil.js';
import { mapActions, mapGetters } from 'vuex';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import moment from 'moment';
import Table from '@/components/Table/index.vue';
import TvosDatePicker from '@/components/Input/TvosDatePicker';
import PicSelect from '@/components/Input/PicSelect'

export default {
  name: 'ExperienceBasedAccommodationCustomers',
  props: {
    filterPic: Boolean,
  },
  data() {
    return {
      filter: {
        fromDate:  moment()
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        isCancelled: null,
        picId: this.$store.getters.getUserId
      },
      total: 0,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      menuDate: false,
      headerPurchaseEvaluation: [
        {
          text: '顧客番号',
          value: 'id',
          width: '80px',
          fieldName: 'client.id',
        },
        {
          text: '個人/法人',
          value: 'isCompany',
          width: '53px',
          fieldName: 'client.isCompany',
        },
        {
          text: '顧客名',
          value: 'name',
          width: '125px',
          sortable: false,
        },
        {
          text: '体験宿泊開始日',
          value: 'lastBooking.checkInDate',
          width: '116.77px',
          fieldName: 'booking.checkInDate',
        },
        {
          text: '日数',
          value: 'numDate',
          width: '24px',
          sortable: false,
        },
        {
          text: '施設',
          value: 'lastBooking.room.roomType.facility.name',
          width: '66px',
          fieldName: 'facility.name',
        },
        {
          text: 'ステータス',
          value: 'lastBooking.computed.status',
          width: '97px',
          fieldName: 'bookingComputed.status',
        },
        {
          text: '予約詳細(PMS)',
          value: 'ReservationDetails',
          align: 'center',
          width: '82px',
          sortable: false,
        },
        {
          text: '営業担当',
          value: 'pic.name',
          width: '56px',
          fieldName: 'pic.name',
        },
        {
          text: '顧客詳細',
          value: 'CustomerDetails',
          align: 'center',
          width: '48px',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'searchClientListExp',
      'canceledReservation',
    ]),
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.$refs.table.reset();
      }
    }
  },
  mounted() {
    this.$refs.table.reset();
  },

  methods: {
    ...mapActions(['actionGetSearchClientListExp']),
    calculateDays,
    async callAPI(variables) {
      await this.actionGetSearchClientListExp({
        fromDate: variables.filter.fromDate,
        isCancelled: variables.filter.isCancelled,
        picId: this.filterPic ? variables.filter.picId : null,
        skip: variables.pagination.skip,
        take: variables.pagination.take,
        orderBy: variables.orderBy,
      }).then(result => {
        this.total = result;
      });
    },

    redirePagePMS (item) {
      if (item.lastBooking) {
        this.$openRoute.booking(item.lastBooking.id);
      }
    },
    
  },
  components: {
    Table,
    TvosDatePicker,
    PicSelect,
  }
};
</script>

<style lang="scss" scoped>
.w-80 {
  width: 80%;
}
::v-deep {
  .color-title {
    background: unset !important;
    color: #000000;
    word-break: keep-all;
  }
  .icon-custom {
    color: #333333;
    font-size: 18px;
  }

  .v-btn__content {
    // color: #000000;
    font-weight: 500;
    .mdi-human-handsup {
      color: #fff !important;
    }
  }
  .v-list-item__title {
    color: #000000;
    font-weight: 500;
    font-size: 12px !important;
  }
  .v-select-custom.v-input *,
  .v-btn-custom * {
    font-size: 10px !important;
  }
  .v-select-custom {
    width: 80px !important;
    height: 24px !important;
  }
  .table-custom {
    tr {
      th {
        font-size: 14px !important;
      }
      td {
        font-size: 16px !important;
      }
    }
  }
  .v-data-footer__select {
    font-size: 13px !important;
    color: #000000;
    font-weight: 500;
  }
}
.canceled-reservation {
  margin-left: 8px;
}
</style>
