<template>
  <mypage>
    <template v-slot:default>
      <div class="mx-10">
        <ExperienceBasedAccommodationCustomers filterPic />
      </div>
    </template>
  </mypage>
</template>

<script>
import Mypage from '../Mypage.vue';
import ExperienceBasedAccommodationCustomers from '@/views/customer/response/ExperienceBasedAccommodationCustomers/ExperienceBasedAccommodationCustomers.vue';

export default {
  components: { 
    Mypage,
    ExperienceBasedAccommodationCustomers,
  },
  name: 'MypageListTab',
}
</script>